import React from "react";
import ButtonPrimary from "../../Button/Primary.js";
import Error404 from "../../../assets/images/Error404.svg";
import "./style.css";

const NotFound = () => {
    return (
        <div className="not-found">
            <img src={Error404} alt="Error404 img" id="error404-img"/>
            <p className="error404-text">Sorry, the page you were looking for was not found.</p>
            <ButtonPrimary text="Return to Home" url="/"/>
        </div>
    )
}

export default NotFound;