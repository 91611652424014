import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home/index.js"
import Services from "../pages/Services/index.js";
import AboutUs from "../pages/AboutUs/index.js";
import Blog from "../pages/Blog/index.js";
import BlogDetail from "../pages/BlogDetail/index.js";
import Contact from "../pages/Contact/index.js";
import NotFound from "../pages/NotFound/index.js";
import Reviews from "../pages/Reviews/index.js";

const RoutingPath = {
  Home: '/',
  AboutUs: '/about',
  Services: '/services',
  Blog: '/blog',
  BlogDetail: '/blog/:slugId',
  Contact: '/contact',
  NotFound: '*',
  Reviews: '/reviews',
}

const Routing = () => {
  return (
    <Routes>
      <Route path={RoutingPath.Home} element={<Home />} />
      <Route path={RoutingPath.Services} element={<Services />} />
      <Route path={RoutingPath.AboutUs} element={<AboutUs />} />
      <Route path={RoutingPath.Blog} element={<Blog />} />
      <Route path={RoutingPath.BlogDetail} element={<BlogDetail />} />
      <Route path={RoutingPath.Contact} element={<Contact />} />
      <Route path={RoutingPath.NotFound} element={<NotFound />} />
      <Route path={RoutingPath.Reviews} element={<Reviews />} />
    </Routes>
  );
};

export default Routing;