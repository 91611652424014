import './style.css';

const Journey = (props) => {

    const { header, subHeader, description } = props;

    return (
        <div className='journey'>
            <div className='top-section'>
                <p className='header'>{header}</p>
                <p className='subheader'>{subHeader}</p>
            </div>
            <div className='bottom-section'>
                {description}
            </div>
        </div>
    )
}

export default Journey;