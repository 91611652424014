import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../../constants.js";
import "./style.css";

// components
import Banner from "../../Banner/index.js";
import SampleBanner from "../../../assets/images/sample-banner.jpg";
import BlogCard from "../../BlogCard/index.js";

// utility
import { truncateString } from "../../../Utilities/TruncateString";

const Blog = () => {

    const [blogs, setBlogs] = useState([])
    const getBlogPosts = async () => {
        try {
            await fetch(BASE_URL + "/thumbnails")
                .then((res) => res.json())
                .then((data) => {
                    if (!data.message) {
                        setBlogs(data);
                    }
                })
        } catch (err) {
            console.log(err.message);
        }
    }

    useEffect(() => {
        getBlogPosts();
    }, [])

    return (
        <div>
            <Banner bannerImageName={SampleBanner} bannerText="Blog" />
            <div className="blog-container section">
                {
                    blogs.map((blog, idx) =>
                        <BlogCard
                            image={blog.thumbnailimage}
                            title={blog.title}
                            subTitle={new Date(blog.created_at).toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" })}
                            description={truncateString(blog.excerpt, 120, true)}
                            url={'/blog/' + blog.slug}
                            key={`blogcard-${idx}`}
                        />
                    )
                }
            </div>
        </div>
    )
}

export default Blog;