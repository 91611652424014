import React from "react";
import './style.css'

const Card = (props) => {

  let bgColor = 'var(--primary)'
  if (props.bgColor === "secondary1") {
    bgColor = 'var(--secondary1)'
  } else if (props.bgColor === 'secondary2') {
    bgColor = 'var(--secondary2)'
  }

  return (
    <div 
      className="heystack_card d-flex flex-column justify-content-end" 
      style={{background: bgColor}}
    >
      <div className="content">
        <h3>{props.title}</h3>
        <p>{props.description}</p>
      </div>
    </div>
  );
};

export default Card;