import './style.css';

const Founder = (props) => {

    const { header, subHeader, image, description } = props;

    return (
        <div className='founder'>
            <div className='top-section'>
                <img src={image} alt={image} className='founder-img' />
                <div>
                    <p className='header'>{header}</p>
                    <p className='subheader'>{subHeader}</p>
                </div>
            </div>
            <div className='bottom-section'>
                {description.split('\\n').map((paragraph, index) => {
                    return <p key={index}>{paragraph}</p>;
                })}
            </div>
        </div>
    )
}

export default Founder;