import React from "react";
import "./style.css";

// components
import ButtonPrimary from "../../Button/Primary.js";
import ButtonSecondary from "../../Button/Secondary.js";
import Hero from "../../Hero/index.js";
import ImageDescription from "../../ImageDescription/index.js";
import Card from "../../Card/index.js";

// immages
import HeroImage from "../../Hero/heroImage.jpeg";
import image1 from "../../../assets/images/home/home_img1.jpg";
import image2 from "../../../assets/images/home/home_img2.jpg";
import image3 from "../../../assets/images/home/home_img3.jpg";

const Home = () => {

    return (
        <div className="home">
            {/* Hero Section */}
            <Hero
                image={HeroImage}
                title='HeyStack'
                description='Your Trusted Industry Consultant and Professional Training Partner.'
                buttonText='Our Services'
                buttonLink='/services'
            />

            {/* About HeyStack --> Link to AboutPage */}
            <ImageDescription
                title="About HeyStack"
                description="HeyStack is a startup that prides itself as the Next-Gen Knowledge-Skill-Based Hiring Disruptor Platform that looks to open a robust eMarketplace for students to source for overseas internship opportunities."
                children={<ButtonSecondary text="More About Us" url="/about" />}

                imageUrl={image1}
                imageWidth="300px"
                imageHeight="380px"
                imagePos="right"
            />

            {/* What we Provide --> Link to ServicesPage */}
            <div className="services-link_container p-5 mt-4">
                <div className="d-flex flex-column align-items-center">
                    <h2>What we provide</h2>
                    <p>HeyStack is a startup that prides itself as the Next-Gen Knowledge-Skill-Based Hiring Disruptor Platform that looks to open a robust eMarketplace.</p>
                    <ButtonPrimary text="Explore Services" url="/services" />
                </div>
                <div className="services_cards-container mt-5 d-flex justify-content-center">
                    <img src={image2} alt="asset" />
                    <Card
                        bgColor="secondary1"
                        title="Internship Opportunities"
                        description="HeyStack is a startup that prides itself as the Next-Gen Knowledge-Skill-Based Hiring Disruptor Platform that looks to open a robust eMarketplace."
                        children=""
                    />
                    <Card
                        bgColor="secondary2"
                        title="Industrial Consultancy and Training"
                        description="HeyStack is a startup that prides itself as the Next-Gen Knowledge-Skill-Based Hiring Disruptor Platform that looks to open a robust eMarketplace."
                        children=""
                    />
                </div>
            </div>

            {/* Our Partners */}
            {/* <div className="p-5 mb-4">
                <h2 className="text-center mb-4">Our Partners</h2>
                <div className="partners-container d-flex justify-content-center">
                    <img src="#" alt="partner" />
                    <img src="#" alt="partner" />
                    <img src="#" alt="partner" />
                </div>
            </div> */}

            {/* Get in Touch --> Link to ContactPage */}
            <ImageDescription
                title="Get in Touch"
                description="HeyStack is a startup that prides itself as the Next-Gen Knowledge-Skill-Based Hiring Disruptor Platform that looks to open a robust eMarketplace for students to source for overseas internship opportunities."
                children={<ButtonSecondary text="Contact Us" url="/contact" />}

                imageUrl={image3}
                imageWidth="450px"
                imageHeight="450px"
                imagePos="left"
            />
        </div>
    )
}

export default Home;