import React from "react";
import './style.css';

const ImageDescription = (props) => {

  const imageDimensions = {
    width: props.imageWidth,
    height: props.imageHeight,
  }

  return (
    <div id="imageDesc-container" className={(props.imagePos === 'right' ? 'flex-row' : 'flex-row-reverse') + ' image_description d-flex justify-content-center align-items-center p-5'}>
      <div className="w-50">
        <h2>{props.title}</h2>
        <p>{props.description}</p>
        {props.children}
      </div>
      <div className={'img-' + props.imagePos + " imgDesc-imgcontainer"}>
        <img 
          style={imageDimensions}
          src={props.imageUrl} 
          alt="asset"
        />
        <div className={'image-' + props.imagePos}></div>
      </div>
      <div className="image_description_background"></div>
    </div>
  );
};

export default ImageDescription;