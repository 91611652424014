import React from "react";

// components
import Banner from "../../Banner/index.js";
import ImageDescription from "../../ImageDescription/index.js";
import InternshipAdvantage from "../../Advantage/index.js";
import AdvantageTick from "../../AdvantageTick/index.js";

// icons
import SampleBanner from "../../../assets/images/sample-banner.jpg";
import AdvantageIcon from "../../../assets/icons/advantagetick.png";

//images
import banner1Img from  "../../../assets/images/servicesImage1.jpg";
import banner2Img from "../../../assets/images/servicesImage2.jpg";

const Services = () => {
    return (
        <div>
            <Banner bannerImageName={SampleBanner} bannerText="Services" />

            {/* Internship Opportunities: Advantages */}
            <div>
                <ImageDescription
                    title="Internship Opportunities"
                    description="eMarketplace"
                    imageUrl={banner1Img}
                    imageWidth="400px"
                    imageHeight="400px"
                    imagePos="right"
                >
                    <InternshipAdvantage
                        heading="Advantages"
                        description="Heystack is a startup that prides itself as the Next-Gen Knowledge-Skill-Based Hiring Disruptor Platform."
                    />
                    <InternshipAdvantage
                        heading="Advantages"
                        description="Heystack is a startup that prides itself as the Next-Gen Knowledge-Skill-Based Hiring Disruptor Platform."
                    />
                </ImageDescription>
            </div>

            {/* Technical Consultancy: Advantages */}
            <div>
                <ImageDescription
                    title="Industrial Consultancy and Training"
                    description="Heystack is a startup that prides itself as the Next-Gen Knowledge-Skill-Based Hiring Disruptor Platform that looks to open a robust eMarketplace for students to source for overseas internship opportunities."
                    imageUrl={banner2Img}
                    imageWidth="360px"
                    imageHeight="360px"
                    imagePos="left"
                ><br></br>
                <AdvantageTick
                    imageUrl={AdvantageIcon}
                    description="Advantage"
                />
                <AdvantageTick
                    imageUrl={AdvantageIcon}
                    description="Advantage"
                />
                </ImageDescription>
            </div>

        </div>
    );
};

export default Services;
