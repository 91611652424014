import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../../constants.js";

// components
import Hero from '../../Hero/index.js';
import ImageDescription from '../../ImageDescription/index.js';

// utility
import ButtonPrimary from '../../Button/Primary.js';
import { truncateString } from "../../../Utilities/TruncateString";

const BlogDetail = () => {

    const { slugId } = useParams();
    const [blogDetail, setBlogDetail] = useState([]);

    const getBlogDetail = async () => {
        try {
            await fetch(BASE_URL + "/blog/" + slugId, {
                method: "GET",
                headers: {
                    "content-type": "application/json",
                }
            })
                .then(async (res) => {
                    if (!res.ok) {
                        const errData = await res.json();
                        throw new Error(errData.message);
                    } else {
                        return res.json();
                    }
                })
                .then((data) => {
                    if (!data.blog) {
                        setBlogDetail(data.templates);
                    }
                })
                .catch((err) => {
                    if (err.message === 'Invalid or Expired token') {
                        sessionStorage.removeItem('token');
                        alert('Your session has expired');
                        window.location.reload();
                    }
                })
        } catch (err) {
            console.log(err.message);
        }
    }

    useEffect(() => {
        getBlogDetail();
    }, []);

    return (
        <div className="blog-detail">
            <div className="section">
                {
                    blogDetail.map((blog) =>
                        <>
                            {blog.template === 'Paragraph'
                                ? <div className='mt-3 mb-4'>
                                    <h2>{blog.fields[0].content}</h2>
                                    <p>{blog.fields[1].content}</p>
                                </div>
                                : null}

                            {blog.template === 'Centered Text'
                                ? <div className="d-flex flex-column align-items-center m-3">
                                    <h2>{blog.fields[0].content}</h2>
                                    <p style={{ 'max-width': 600, 'text-align': 'center' }}>{blog.fields[1].content}</p>
                                </div>
                                : null}

                            {blog.template === 'Image + Text'
                                ? <ImageDescription
                                    title={blog.fields[0].content}
                                    description={truncateString(blog.fields[1].content, 120, true)}

                                    imageUrl={blog.fields[3].content}
                                    imageWidth="370px"
                                    imageHeight="300px"
                                    imagePos={blog.fields[2].content}
                                />
                                : null}

                            {blog.template === 'Image + Text + Button'
                                ? <ImageDescription
                                    title={blog.fields[0].content}
                                    description={truncateString(blog.fields[1].content, 120, true)}
                                    children={<ButtonPrimary text={blog.fields[4].content} url={blog.fields[6].content} buttonColor={blog.fields[5].content} />}

                                    imageUrl={blog.fields[3].content}
                                    imageWidth="300px"
                                    imageHeight="380px"
                                    imagePos={blog.fields[2].content}
                                />
                                : null}

                            {blog.template === 'Centered Images'
                                ? <div className="services-link_container p-5">
                                    <div className="d-flex flex-column align-items-center">
                                        <h2>{blog.fields[0].content}</h2>
                                    </div>
                                    <div className="d-flex justify-content-center gap-3 mt-3">
                                        <img src={blog.fields[1].content} alt='asset' style={{ minWidth: 280, flex: 1, objectFit: 'cover' }} />
                                        <img src={blog.fields[2].content} alt='asset' style={{ minWidth: 280, flex: 1, objectFit: 'cover' }} />
                                        <img src={blog.fields[3].content} alt='asset' style={{ minWidth: 280, flex: 1, objectFit: 'cover' }} />
                                    </div>
                                </div>
                                : null}

                            {blog.template === 'Image Banner'
                                ? <Hero
                                    title={blog.fields[0].content}
                                    description={blog.fields[1].content}
                                    image={blog.fields[2].content}
                                    buttonText={blog.fields[3].content}
                                    buttonColor={blog.fields[4].content}
                                    buttonLink={blog.fields[5].content}
                                />
                                : null}
                        </>
                    )
                }
            </div>
        </div>
    )
}

export default BlogDetail;