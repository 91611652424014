import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import Logo from "../../assets/images/Logo.png";
import "./styles.css";

const NavBar = (props) => {
  const { noBackground = false, expanded = undefined } = props;
  const navigate = useNavigate();
  const currentPath = window.location.pathname;
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const navigation = (path) => {
    switch (path) {
      case 'Home':
        navigate('/');
        break;
      case 'About':
        navigate('/about');
        break;
      case 'Services':
        navigate('/services');
        break;
      case 'Blog':
        navigate('/blog');
        break;
      case 'Contact':
        navigate('/contact');
        break;
      case 'Reviews':
        navigate('/reviews');
        break;
      default:
        navigate('/');
        break;
    }
  }

  return (
    <Navbar
      expand="lg"
      expanded={expanded === undefined ? undefined : "true"}
      className={"section" +
        (isSticky
          ? " sticky"
          : (noBackground && currentPath === '/' ? " no-background" : " non-sticky ")
        )
      }
    >
      <Navbar.Brand onClick={() => navigation('Home')}>
        <img
          src={Logo}
          alt="Logo"
          height="30"
          className="d-inline-block align-top"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link onClick={() => navigation('Home')}>Home</Nav.Link>
          <Nav.Link onClick={() => navigation('Services')}>Services</Nav.Link>
          <Nav.Link onClick={() => navigation('Blog')}>Blog</Nav.Link>
          <Nav.Link onClick={() => navigation('Reviews')}>Reviews</Nav.Link>
          <Nav.Link onClick={() => navigation('About')}>About Us</Nav.Link>
          <Nav.Link onClick={() => navigation('Contact')}>Contact</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar >
  );
};

export default NavBar;