import React from 'react';
import advantagetick from '../../assets/icons/advantagetick.png';
import './style.css';

const AdvantageTick = ({ description }) => {
    return (
      <div className="advantage-tick-container">
        <div className="image-container">
          <img src={advantagetick} alt="Tick" className="image" />
        </div>
        <div className="description-container">
          <p>{description}</p>
        </div>
      </div>
    );
  };
  
  export default AdvantageTick;