import ButtonPrimary from "../../components/Button/Primary";
import './style.css';

const hero = (props) => {

    const { title, description, image, buttonText, buttonColor, buttonLink } = props;

    return (
        <div className="hero-image d-flex align-items-center"
            style={{
                'background-image': `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${image})`
            }}>
            <div className="hero-text">
                <h1>{title}</h1>
                <p>{description}</p>
                <ButtonPrimary text={buttonText} url={buttonLink} buttonColor={buttonColor}/>
            </div>
        </div>
    )
}

export default hero;