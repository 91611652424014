import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../constants.js";

// components
import ReviewCard from "../../ReviewCard";
import Banner from "../../Banner/index.js";
import SampleBanner from "../../../assets/images/sample-banner.jpg";
import thanks from "../../../assets/icons/thanks.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaStar } from "react-icons/fa";
import './style.css';
import Swal from "sweetalert2";
import form_img from "../../../assets/images/review_form_img.jpg";


const Reviews = () => {
    const [reviewData, setReviewData] = useState([]);

    useEffect(() => {
        fetch(BASE_URL + "/review/getReviewsByStatusId/1")
            .then((response) => response.json())
            .then((data) => setReviewData(data))
    }, []);

    var settings = {
        dots: true,
        infinite: true,
        speed: 2000,
        slidesToShow: reviewData.length > 1 ? 2 : 1,
        autoplay: true,
        autoplayspeed: 200,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };


    let [starValue, setStarValue] = useState(0);
    console.log(starValue, 'outside');

    function setClick1() {
        if (starValue === 1) {
            setStarValue(0);
            const ratingErrorMessage = document.getElementById("rating-error");
            const radio = document.getElementsByName("star");
            radio.forEach(radio => radio.checked = false);
            ratingErrorMessage.innerHTML = "Rating is required";
        }
        else {
            setStarValue(1);
            const ratingErrorMessage = document.getElementById("rating-error");
            ratingErrorMessage.innerHTML = "";
        }
    }
    function setClick2() {
        setStarValue(2);
    }
    function setClick3() {
        setStarValue(3);
        console.log(starValue);
    }
    function setClick4() {
        setStarValue(4);
        console.log(starValue);
    }
    function setClick5() {
        setStarValue(5);
        console.log(starValue);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const ratingValue = document.getElementById("s1").value;
        const nameValue = document.getElementById("name-field-input").value;
        const messageValue = document.getElementById("message-field-input").value;

        if (nameValue == "") {
            const nameErrorMessage = document.getElementById("name-error");
            nameErrorMessage.innerHTML = "Name is required";
        }
        if (nameValue != "") {
            const nameErrorMessage = document.getElementById("name-error");
            nameErrorMessage.innerHTML = "";
        }
        if (ratingValue == 0) {
            const ratingErrorMessage = document.getElementById("rating-error");
            ratingErrorMessage.innerHTML = "Rating is required";
        }
        if (ratingValue > 0) {
            const ratingErrorMessage = document.getElementById("rating-error");
            ratingErrorMessage.innerHTML = "";
        }
        if (messageValue == "") {
            const messageErrorMessage = document.getElementById("message-error");
            messageErrorMessage.innerHTML = "Message is required";
        }
        if (messageValue != "") {
            const messageErrorMessage = document.getElementById("message-error");
            messageErrorMessage.innerHTML = "";
        }
        if (nameValue != "" && messageValue != "" && ratingValue != 0) {
            const reviewData = {
                name: nameValue,
                message: messageValue,
                rating: ratingValue
            };

            await fetch(BASE_URL + "/review/submitReview",
                {
                    method: "POST",
                    headers: { "content-type": "application/json" },
                    body: JSON.stringify(reviewData)
                }
            );

            Swal.fire({
                title: "Review submitted Successfully",
                text: "Thank you for your review.",
                iconHtml: `<img src=${thanks} width="60px" height="60px"/>`,
                timer: 1500,
                showConfirmButton: false,
            }).then(() => { window.location.reload(); })
        }
    }
    const handleNameInput = (event) => {
        const nameErrorMessage = document.getElementById("name-error");
        if (event.target.value == "") {
            nameErrorMessage.innerHTML = "Name is required";
        } else {
            nameErrorMessage.innerHTML = "";
        }
    }
    const handleMessageInput = (event) => {
        const messageError = document.getElementById("message-error");
        if (event.target.value == "") {
            messageError.innerHTML = "Message is required";
        } else {
            messageError.innerHTML = "";
        }
    }
    return (
        <div>
            <Banner bannerImageName={SampleBanner} bannerText="Reviews" />

            <h2 className="reviews-heading section">Share your experiences with us!</h2>
            <div className="reviews-container section">
                <form className="reviews-form" method="post" onSubmit={handleSubmit}>
                    <div className="name-field">
                        <label>Name</label><br />
                        <input type="text" id="name-field-input" class="form-control" onInput={handleNameInput} />
                        <span class="error-msg" id="name-error"></span>
                    </div>
                    <div className="message-field">
                        <label>Message</label><br />
                        <textarea id="message-field-input" className="form-control" onInput={handleMessageInput} />
                        <span class="error-msg" id="message-error"></span>
                    </div>
                    <div className="service-field">
                        <label>Rate Our Services</label><br />
                        <div className="d-flex flex-row-reverse justify-content-end">
                            <input type="radio" name="star" class="star-rating" id="s5" value={starValue} onClick={setClick5}></input>
                            <label htmlFor="s5"><FaStar size="30" /></label>

                            <input type="radio" name="star" class="star-rating" id="s4" value={starValue} onClick={setClick4}></input>
                            <label htmlFor="s4"><FaStar size="30" /></label>

                            <input type="radio" name="star" class="star-rating" id="s3" value={starValue} onClick={setClick3}></input>
                            <label htmlFor="s3"><FaStar size="30" /></label>
                            <input type="radio" name="star" class="star-rating" id="s2" value={starValue} onClick={setClick2}></input>
                            <label htmlFor="s2"><FaStar size="30" /></label>
                            <input type="radio" name="star" class="star-rating" id="s1" value={starValue} onClick={setClick1}></input>
                            <label htmlFor="s1"><FaStar size="30" /></label>
                        </div>
                        <span class="error-msg" id="rating-error"></span>
                    </div>
                    <input type="submit" value="Submit" className="heystack_formBtn reviewBtn" />
                </form>
                <div className="reviews-img-container">
                    <img src={form_img} width="100%" height="100%" />
                </div>
            </div>
            <h2 className="reviews-heading section">Feedback by our customers</h2>
            <div className="card-grid-container">
                <div className="section">
                    <Slider {...settings}>
                        {reviewData.map((element) => (
                            <div>
                                <ReviewCard key={element.reviewid} name={element.name} message={element.message} date={element.created_at} rating={element.rating} />
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    );
}

export default Reviews;