import React, { useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { BASE_URL } from "../../../constants.js";
import $ from 'jquery';
import './style.css';

// components
import Banner from "../../Banner/index.js";
import SampleBanner from "../../../assets/images/sample-banner.jpg";
import Social from "../../Social/index.js";
import thanks from "../../../assets/icons/thanks.png";
import Swal from "sweetalert2";

const Contact = () => {
    const [captchaVal, setCaptchaVal] = useState(null);
    const recaptchaRef = useRef(null);

    async function sendContact(e) {
        e.preventDefault();
        try {
            await fetch(BASE_URL + "/contacts", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    "name": $("#inputName").val(),
                    "email": $("#inputEmail").val(),
                    "message": $("#inputMessage").val(),
                    "receiverEmail": process.env.EMAIL_RECEIVER_USER,
                })
            })
                .then((res) => res.json())
                .catch((error) => {
                    console.log(error);
                })
            Swal.fire({
                title: "Details submitted successfully",
                text: "Thank you. We will get back to you shortly.",
                iconHtml: `<img src=${thanks} width="60px" height="60px"/>`,
                timer: 2000,
                showConfirmButton: false,
            }).then(() => {
                $("#inputName").val('');
                $("#inputEmail").val('');
                $("#inputMessage").val('');
                console.log('contact sent')
            })
            if (recaptchaRef.current) {
                recaptchaRef.current.reset();
            }
            console.log('contact sent');
        } catch (err) {
            console.log(err.message);
        }
    }

    return (
        <div>
            <Banner bannerImageName={SampleBanner} bannerText="Contact" />

            {/* Contact Form */}
            <div className="contact-container" >
                <form onSubmit={sendContact}>
                    <div className="form-group">
                        <label htmlFor="inputName">Name</label>
                        <input type="text" className="form-control mb-3" id="inputName" placeholder="Enter your name here" required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="inputEmail">Email</label>
                        <input type="email" className="form-control mb-3" id="inputEmail" placeholder="Enter your email here" required />
                    </div>
                    <div className="form-group mb-5">
                        <label htmlFor="inputMessage">Message</label>
                        <textarea className="form-control" id="inputMessage" rows="3" placeholder="Enter your message here" required></textarea>
                    </div>
                    <div className="recaptcha-wrapper">
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey="6LfiIUIpAAAAAHyw8vrAMaT_B8tFjkBv-OiN5QQk"
                            onChange={(val) => setCaptchaVal(val)}
                        />
                    </div>
                    <input type="submit" value="Contact Us" className="heystack_formBtn" disabled={!captchaVal} />
                </form>
                <div>
                    <h2>Contact Us</h2>
                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when printer took a galley of type and scrambled.</p>
                    <Social />
                </div>
            </div>
        </div>
    );
}

export default Contact;