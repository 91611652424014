import React from 'react';
import icon from '../../assets/icons/advantageicon.png';
import './style.css';

const Advantage = ({ heading, description }) => {
  return (
    <div className="component-container">
      <img src={icon} alt="Icon" className="icon" />
      <h3 className="heading">{heading}</h3>
      <p className="description">{description}</p>
    </div>
  );
};

export default Advantage;
