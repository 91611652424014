import React from "react";
import "./style.css";

const Social = () => {

    return (
        <div>
            <div className="social-text">
                Copyright © 2023 HeyStack Pte Ltd
            </div>
            <a href="https://www.linkedin.com/company/heystack-global" className="icon" id="linkedin" target="_blank"></a>
            <a href="https://example.com" className="icon" id="instagram"  target="_blank"></a>
            <a href="https://example.com" className="icon" id="facebook"  target="_blank"></a>
        </div>
    )
}

export default Social;