import React from 'react';
import { FaStar } from "react-icons/fa";
import './style.css';

const ReviewCard = (props) => {
    var currentDate = Date.parse(props.date);

    var options = { 
    year: 'numeric', 
    month: 'long', 
    day: 'numeric' 
    };

    var formattedDate = new Intl.DateTimeFormat('en-US', options).format(currentDate);
    const ratingVal = parseInt(props.rating);
        return(
        <div className='p-5 reviewCardContainer'>
           <h2>{props.name}</h2>
           <div className='d-flex'>
           <FaStar color={ratingVal>=1?"#EFBC68":"grey"} size="30"/>
           <FaStar color={ratingVal>=2?"#EFBC68":"grey"} size="30"/>
           <FaStar color={ratingVal>=3?"#EFBC68":"grey"} size="30"/>
           <FaStar color={ratingVal>=4?"#EFBC68":"grey"} size="30"/>
           <FaStar color={ratingVal>=5?"#EFBC68":"grey"} size="30"/>
           </div>
           <p className='reviewCardMessage'>
            {props.message}
           </p>
           <p className='reviewCardDate'>
            {formattedDate}
           </p>
        </div>
    );
}

export default ReviewCard;