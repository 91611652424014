import React from "react";
import "./style.css";

// components
import Banner from "../../Banner/index.js";
import SampleBanner from "../../../assets/images/sample-banner.jpg";
import ImageDescription from "../../ImageDescription/index.js"
import Founder from "../../Founder/index.js";
import Journey from "../../Journey/index.js";

// utility
import Logo from "./Logo_with_background.png";
import Founder1 from "./Founder_Image/Jeremiah.jpeg";
import Founder2 from "./Founder_Image/Nicholas.jpeg";
import Founder3 from "./Founder_Image/Derrick.jpeg";
import lines from "../../../assets/images/3_orange_line.png";

const AboutUs = () => {

    return (
        <div className="about-us">
            <Banner bannerImageName={SampleBanner} bannerText="About Us" />

            <ImageDescription
                title="HeyStack's Vision"
                description="HeyStack is a startup that prides itself as the Next-Gen Knowledge-Skill-Based Hiring Disruptor Platform that looks to open a robust eMarketplace for students to source for overseas internship opportunities."

                imageUrl={Logo}
                imageWidth="370px"
                imageHeight="300px"
                imagePos="left"
            />

            <div className="journey-section container">
                <h2>Our Journey</h2>
                <div className="journey-container">
                    <Journey
                        header='20XX'
                        subHeader='Milestone'
                        description='Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy.'
                    />
                    <Journey
                        header='2022'
                        subHeader='Milestone'
                        description='Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy.'
                    />
                    <Journey
                        header='2023'
                        subHeader='Milestone'
                        description='Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy.'
                    />
                    <img src={lines} className="journey_img" />
                </div>
            </div>

            <div className="founder-section section">
                <h2 className="founder-section-header">Founders</h2>
                <div className="founder-card-container">
                    <Founder
                        header='Jeremiah'
                        subHeader='Title'
                        image={Founder1}
                        description='Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
                    />
                    <Founder
                        header='Nicholas'
                        subHeader='Title'
                        image={Founder2}
                        description='As a current Bachelor of Computer Science student with a Diploma in Computer Engineering, I am dedicated to enhancing my technical skill set and keeping with emerging technologies. As an entrepreneur, I am highly motivated to innovate and excel in the tech industry. \nMy particular area of interest is working with data and backend technologies, where I am adept at leveraging analytical tools and techniques to generate valuable insights. With a solid background in programming, machine learning, analytics, and data management, I possess a robust foundation in this field. \nI am confident that my skills and expertise can be an asset to your organization in achieving its objectives.'
                    />
                    <Founder
                        header='Derrick'
                        subHeader='Title'
                        image={Founder3}
                        description = "Experienced System Architect/Agile Coach/Technical Manager with a demonstrated history of working in consumer electronics, signal and data processing, warehousing and analytics across many areas such as defense, healthcare, financial, and automotive industries.\nHands-on Skills in DevOps, Embedded tech, and Server Administration, C++, Dart, Python, RDBMS, and Unstructured Databases (Postgres, Oracle, MySQL, MongoDB, Redis) and Docker Orchestration/Containerization in developing REST or socket (MQ) based Backend Systems, DSP Algorithms, and Mobile Applications.\nStrong technical and functional leadership skills capable of leading and coaching multiple lean and efficient Agile Scrum development teams, multiples of headcount sizes of 5 to 11 consisting of diverse engineering/software disciplines (Component or Feature Teams) to deliver holistic products with high quality."
                    />
                </div>
            </div>
        </div>
    )
}

export default AboutUs;