import NavBar from "../NavBar/index.js";
import Social from "../Social/index.js";
import './style.css';

const Footer = () => {
    return (
        <div className="footer section">
            <NavBar expanded={true} />
            <Social />
        </div>
    );
}

export default Footer;